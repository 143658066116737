import App from './App.vue';
import router from "@/router";
import { createApp } from 'vue';
import mitt from 'mitt';
import store from "@/store";
import vueVimeoPlayer from 'vue-vimeo-player';

import '@/assets/main.css'

const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.config.productionTip = false;

app.use(vueVimeoPlayer);
app.use(router);
app.use(store);
app.mount('#app');
