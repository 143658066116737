<template>

    <header class="header" :class="{ 'overlay-menu' : burgerVisibility }">
        <div class="flex items-center justify-between lg:px-10 px-5 py-3">
            <routerLink to="/home" class="brand">
                <img src="@/assets/images/icons/s4.png" alt="brand-icon"> 
            </routerLink>
            <a class="menu-toggle" @click="changeVisibility()">
                <img :src="require('@/assets/images/icons/' + (!burgerVisibility ? 'menu.svg' : 'close-menu.png'))" alt="menu"> 
            </a>
        </div>
        
        <ul class="menu-items">
            <li>
                <a :href="$router.resolve({name: 'homePage'}).href" @click="closeMenu()">HOME</a>
                <!-- <routerLink to="/home" @click="closeMenu()">HOME</routerLink> -->
            </li>
            <li>
                <a :href="$router.resolve({name: 'ourPlatform'}).href" @click="closeMenu()">OUR PLATFORM</a>
                <!-- <routerLink to="/our-platform" @click="closeMenu()">OUR PLATFORM</routerLink> -->
            </li>
  
            <li>
                <a :href="$router.resolve({name: 'ContactUs'}).href" @click="closeMenu()">CONTACT US</a>
                <!-- <routerLink to="/contact-us" @click="closeMenu()">CONTACT US</routerLink> -->
            </li>
        </ul>
    </header>

</template>

<script>
    export default {
        name: 'qoo-header',

        data() {
            return {
                burgerVisibility: false
            }
        },

        methods: {
            changeVisibility() {
                this.burgerVisibility = !this.burgerVisibility;
            },

            closeMenu() {
                this.burgerVisibility = false;
            }
        },
    }
</script>

<style lang='scss'>
    @import 'Header';
    #logo-img2 {
        display: none;
        width: 90px;
    }
</style>