<template>
  <div class="qoo-main h-full flex flex-col min-h-screen">

    <Header class="shrink-0 grow-0" />

    <div class="grow h crewire-main">
      <router-view></router-view>
    </div>

    <Footer class="shrink-0 grow-0"/>

    <OverlayLoader v-show="isLoading"/>
  </div>
</template>

<script>

import OverlayLoader from "@/components/base/overlay/OverlayLoader.vue";
import Header from "@/components/common/header/Header.vue"
import Footer from "@/components/common/footer/Footer.vue";

export default {
  name: 'App',
  components: { 
    OverlayLoader, 
    Header,
    Footer
  },

  computed: {
    isLoading: {
      get(){
        return this.$store.state.isLoading;
      }
    },
  },

}

</script>
<style scoped>
  .h { min-height: calc(100vh - 100px); }
</style>
