<template>

    <footer class="footer relative">
        <div class="footer-top pt-10">
            <!-- <div class="top-footer flex items-center flex-wrap lg:pb-12 pb-5 lg:px-12 px-5">

                <div class="lg:w-2/3 w-full">
                    <div class="project-description flex items-center lg:justify-start justify-center lg:flex-nowrap flex-wrap">
                        <img src="@/assets/images/icons/s4.png" alt="brand-icon"> 
                        <div class="text lg:text-left text-center">
                            <h1 class="text-white">STAY IN THE LOOP</h1>
                            <p>Stay Up To Date With The Latest News , Announcement, Articles.</p>
                        </div>
                    </div>
                </div>

                <div class="lg:w-1/3 w-full">
                    <form id="newsLetterForm" class="flex items-center lg:justify-start justify-center lg:mt-0 mt-7 lg:flex-nowrap flex-wrap lg:mb-0 mb-7">
                        <input type="text" name="newsLetterEmail" id="newsLetterEmail" placeholder="Enter Your E-Mail" class="lg:w-3/4 w-full lg:mb-0 mb-7">
                        <button type="submit" class="ml-4">SUBSCRIBE</button>
                    </form>
                </div>

            </div>
            <div class="bottom-footer flex items-start flex-wrap lg:py-12 py-5 lg:px-36 px-5">
                <div class="lg:w-1/4 w-full">
                    <ul class="footer-items">
                        <li class="title">
                            COMPANY
                        </li>
                        <li>
                            <routerLink to="/home">Terms Of Service</routerLink>
                        </li>
                        <li>
                            <routerLink to="/home">Privacy Policy</routerLink>
                        </li>
                        <li>
                            <routerLink to="/home">Security</routerLink>
                        </li>
                    </ul>
                </div>
                <div class="lg:w-2/4 w-full lg:pl-36">
                    <ul class="footer-items">
                        <li class="title">
                            REACH OUT
                        </li>
                        <li>
                            <routerLink to="/home">contact us</routerLink>
                        </li>
                        <li>
                            <a href="tel:(+1)123456789">call number: (+1)123456789</a>
                        </li>
                    </ul>
                </div>
                <div class="lg:w-1/4 w-full">
                    <ul class="footer-items socialize">
                        <li class="title">
                            SOCIALIZE
                        </li>
                        <li>
                            <a href=""><img src="@/assets/images/icons/instagram1.png" alt="socialize"></a>
                        </li>
                        <li>
                            <a href=""><img src="@/assets/images/icons/facebook.png" alt="socialize"></a>
                        </li>
                        <li>
                            <a href=""><img src="@/assets/images/icons/telegram.png" alt="socialize"></a>
                        </li>
                        <li>
                            <a href=""><img src="@/assets/images/icons/social.png" alt="socialize"></a>
                        </li>
                    </ul>
                </div>
            </div> -->
           <div class="container mx-auto">
                <div class="grid lg:grid-cols-2 grid-cols-1 text-white">
                    <div class="flex items-center lg:flex hidden">
                        <div>
                            <div class="font-bold text-2xl mb-6">
                                Get In Touch
                            </div>
                            <a href = "mailto: info@crewire.io" class="text-xs text-opacity-70">
                                info@crewire.io
                            </a>
                        </div>
                        <div>
                            <div class="font-bold text-2xl mb-6">
                                Follow Us
                            </div>
                            <div class="flex gap-4 items-center">
                                <a target="_blank" href="https://www.linkedin.com/company/crewire/" class="h-4 block"><img class="h-full" src="@/assets/images/icons/linkedin.svg" alt="socialize"></a>
                                <a target="_blank" href="https://www.youtube.com/@Crewire" class="h-4 block"><img class="h-full" src="@/assets/images/icons/youtube.svg" alt="socialize"></a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="font-bold text-2xl mb-6">STAY IN THE LOOP</div>
                        <div class="text-xs text-opacity-70 mb-6">stay up to date with the latest news, announcement, articles.</div>
                        <form id="newsLetterForm" class="mt-7 mb-7">
                            <div class="grid md:grid-cols-2 grid-cols-1 gap-5">
                                <div>
                                    <input type="text" name="FirstName" id="firstname" placeholder="First Name" class="w-full lg:mb-0 lg:mb-7 mb-3">
                                </div>
                                <div>
                                    <input type="text" name="LastName" id="lastName" placeholder="Last Name" class="w-full lg:mb-0 lg:mb-7 mb-3">
                                </div>
                                <div>
                                    <input type="text" name="email" id="email" placeholder="Email" class="w-full lg:mb-0 lg:mb-7 mb-3">
                                </div>
                                <div>
                                    <input type="text" name="PhoneNumber" id="PhoneNumber" placeholder="Phone Number" class="w-full lg:mb-0 lg:mb-7 mb-3">
                                </div>
                                
                            </div>
                            <div  class="flex justify-end mt-6">
                                <button type="submit" class="bg-white text-gray1">SUBSCRIBE</button>
                            </div>
                        </form>
                    </div>
                    <div class="flex items-between lg:hidden flex">
                        <div>
                            <div class="font-bold text-2xl mb-6">
                                Get In Touch
                            </div>
                            <a href = "mailto: info@crewire.io" class="text-xs text-opacity-70">
                                info@crewire.io
                            </a>
                        </div>
                        <div class="ml-5">
                            <div class="font-bold text-2xl mb-6">
                                Follow Us
                            </div>
                            <div class="flex gap-4 items-center">
                                <a target="_blank" href="https://www.linkedin.com/company/crewire/" class="h-4 block"><img class="h-full" src="@/assets/images/icons/linkedin.svg" alt="socialize"></a>
                                <a target="_blank" href="https://www.youtube.com/@Crewire" class="h-4 block"><img class="h-full" src="@/assets/images/icons/youtube.svg" alt="socialize"></a>
                            </div>
                        </div>
                    </div>
                    <a :href="$router.resolve({name: 'privacy-page'}).href" @click="closeMenu()"
                    class="text-left text-white text-opacity-70 mb-5 underline underline-offset-2 cursor-pointer">Privacy Policy</a>

                    <!-- <routerLink to="/privacy" class="text-left text-white text-opacity-70 mb-5 underline underline-offset-2 cursor-pointer">
                        Privacy Policy
                    </routerLink> -->
                </div>
           </div>
            <div class="border-t border-white border-opacity-20 text-center p-6 text-white text-opacity-20">
                Copyright © 2023 Crewire. All rights reserved.
            </div>
        </div>
        <!-- <div :class="{'flex':modalvisible,'hidden':!modalvisible}" class="modal backdrop-blur-md bg-white/10 fixed top-3 bottom-0 items-center left-0 right-0 z-50 w-full m-auto overflow-hidden">
            <div class="w-96 relative bg-white h-3/4 md:h-2/3 overflow-auto text-gray1 mx-auto rounded-xl">
                <div class="head text-center bg-white h-12 mb-8 fixed">
                    <div class="pt-2">Privacy Policy</div>
                    <div class="absolute right-0 top-2 w-6"><img src="@/assets/images/icons/close.svg" @click="toggleModal()"/></div>
                </div>
                <div class="text-xs mt-14 px-5 Privacy">
                    <p>                    
                        Crewire Inc. (“Crewire”, “we”, “us” or “our”) is committed to protecting your privacy. We’ve done our best to keep this Privacy Policy (“Policy”) clear and concise, but if you have any questions, please contact us using the Contact Us page of the Site or Services (as defined below). 
                    </p>
                    <p>
                        This Privacy Policy details the steps we take to protect your personal information when you visit, access or use our websites 
                        (individually and collectively, the “Site”) and related products and services, including mobile applications, developer platforms, 
                        premium services, plug-ins or any content or information provided as part of the Site (the “Services”). It also describes the personal 
                        information that we collect, the purposes for which we use such information, the steps we take to protect your personal information 
                        and how you can review and correct your personal information. This policy does not apply to information collected by any third party, 
                        including any application, content (including advertising). 
                    </p>
                    <p>
                        This Privacy Policy refers to personal information which is information that can be associated with a 
                        specific person and used to identify that person. Personal information does not include information that 
                        has been de-identified so that it does not identify a specific user, which may be used for any legal purpose. 
                    </p>
                   

                    <h3>1. Your Agreement to This Privacy Policy </h3>

                    <p>
                        By using any Crewire’s Site or Services, you are accepting the terms of this Privacy Policy. 
                        If you do not agree with the terms of this Privacy Policy, you should not use our Site or Services.
                        When using our Site or Services you consent to the collection, transfer, manipulation, storage,
                        disclosure and other uses of your information as described in this Privacy Policy. By supplying information to us, 
                        you authorize Crewire to use your information in the United States, Canada and any other country where Crewire operates. 
                        The Privacy Policy applies no matter what computer or device you use to access the Services. 
                    </p>

                   <p>
                    From time-to-time it will be necessary for us to make updates to this policy. 
                    Your continued use of our Site and / or Services following the posting of changes 
                    to this Privacy Policy will mean that you accept those changes. Please note 
                    that your use of our Site and Services are also governed under our Terms of Service. 
                   </p>

                    <h3>2. Information Collection </h3>

                   <p>
                    Generally, you can visit our Site without entering any personal information; however, to use our Services, you must register with us 
                    and submit certain personally identifiable information. The personal information we collect may include: contact details, 
                    such as your name, title, company/organization name, e-mail address, telephone and fax numbers and physical address; 
                    information about your company and job function; your e-mail marketing preferences; financial information 
                    (including credit card or account information); information such as your nationality and country of residence; 
                    information used to customize and facilitate your use of our Site or Services, including login and technical information; 
                    inquiries about and orders for our products and services; information that assists us in identifying the products and services 
                    that best meet your requirements; event registration information; and feedback from you about our Site or Services. We collect 
                    personal information in a variety of ways, including:Information you provide to us. If you use our Site or Services, you may be 
                    required to provide us certain information. This can include, but is not limited to:
                   </p>
                   <p>
                    <ul>
                        <li>– Information provided at the time of registering to use our Services;</li>
                        <li>– Information provided when you report a problem with our Site or Services;</li>
                        <li>– Records and copies of correspondence (including email addresses), if you contact us;</li>
                        <li>– Your responses to surveys that we might ask you to complete;</li>
                        <li>– Details of transactions you undertake through our Site or Services;</li>
                        <li>– Your search queries on our Site or through our Services;</li>
                        <li>– Information you post to our Site or Services;</li>
                        <li>– Information obtained through your social network account(s), if you authorize us to access such information.</li>
                    </ul>
                   </p>
                   <p>
                    If you are an employer, we may also collect information about your business, payment information and any information 
                    you provide about your employees, including your employees’ contact and application information, 
                    background check information, drug testing results, payroll status and details, and pay structure.
                   </p>
                   <p>
                    Information collected automatically. We collect information about how you use the Site and Services, including what pages you view, 
                    the number of bytes transferred, the links you click, the materials you access and other actions taken within Crewire’s Site or Services. 
                    On pages that you enter with a user ID, we may connect this information with your identity to 
                    determine your potential interests in our products and services. We also collect certain standard 
                    information that your browser sends to every website you visit, such as your Internet Protocol (IP) address, 
                    your browser type and capabilities and language, your operating system, the date and time you access the Site 
                    or Services and the website from which you linked to our Site or Services. The information we collect automatically is 
                    statistical data and may or may not include personal information, but we may maintain it or associate it with personal 
                    information we collect in other ways or receive from third parties. 
                    This helps us to improve and to deliver better and more personalized Services.
                   </p>
                   <p>
                    Information we already have about you. If you, your employer or your employees or other personnel or representatives 
                    have an existing relationship with Crewire, we may already have personal and other information about you or your 
                    employer / employees that you have provided to us or we have otherwise obtained. In order to more efficiently and effectively 
                    provide the Site and Services, notwithstanding any agreement you or the company or other legal entity you represent may have with us, 
                    you hereby authorize us to access and use such information. To the extent any pre-existing agreement(s) between us conflict with this Privacy Policy, 
                    such existing agreement(s) are hereby amended with regard to the subject matter of Terms of Service and this Privacy Policy.
                   </p>
                   <p>
                    Information we collect from third parties. From time to time, we may receive information about you from third parties, 
                    such as identity verification services, payroll vendors, credit bureaus, 
                    mailing list providers and other information. We may also collect information about you that is publicly available.
                   </p>

                   <p>
                    Your address book, calendar and contact information. If you authorize us to do so, we may access your contact list available 
                    on your mobile device, in your email accounts or other information system you connect with the Service in order to locate your friends, 
                    contacts and employees on the Service. With your permission, we may integrate our Service with your calendar system 
                    (such as Google Calendar, Outlook, etc.) and to integrate your calendar entries with your Crewire schedule(s).
                   </p>

                   <p>
                    Geo-location data. You may choose to allow us to use and store information about your location to provide features of
                     our Services, such as work available near you, relevant ads and to improve and customize the Services.
                   </p>

                   <p>
                    Cookies. We use cookies and similar technologies to collect additional website usage data and to improve our Services. 
                    A cookie is a small data file that is transferred to your computer’s hard disk. We may use both session cookies and persistent 
                    cookies to better understand how you interact with our Services, to monitor aggregate usage by our 
                    users and web traffic routing on our Services and to customize and improve our Services. Most internet 
                    browsers automatically accept cookies. You can instruct your browser, by changing its settings, to stop 
                    accepting cookies or to prompt you before accepting a cookie from the websites you visit.
                     However, some Services may not function properly if you disable cookies.
                   </p>

                    <h3>3. Use of Information </h3>

                    <p>
                        We use this information to operate, maintain and provide to you the features and functionality of the Site and Services, as well 
                        as to communicate directly with you, permit you to communicate with others on the Services or on social media or invite others to 
                        join the Services. We may send you email communications that contain information about our products or Services or information about 
                        third parties’ products or services we think you may enjoy. We may also send you Services-related emails or messages (e.g., account 
                        verification, messages and schedules, change or updates to features of the Services, technical and security notices). 
                        We may use the information we have collected from you to enable us to display advertisements to our advertisers’ target audiences.
                    </p>

                    <p>
                        In connection with a job application or inquiry, you may provide us with information about yourself, such as a resume or C.V. Your employer may 
                        also provide us your contact and application information, background check information, drug testing results, payroll status and details, 
                        and pay structure. In addition to other uses disclosed in this Privacy Policy, we may use this information throughout Crewire and affiliates 
                        for the purpose of employment consideration, availability,
                         or otherwise for Crewire, Crewire’s customers and other third parties.
                    </p>

                    <p>
                        Your personal information may be used to improve our Site and Services or to make our Site and Services easier to 
                        use by, for example, eliminating the need for you to repeatedly enter the same information, customizing our Site 
                        or Services (or other sites or services) to your particular preference or interests, or suggesting connections based 
                        on shared work history. We may also use information about you (i) to track and analyze trends and usage in connection
                         with our Site or Services; (ii) to process and deliver contest and promotion entries and rewards; (iii) to protect our 
                         rights or property; (iv) to compare information for accuracy; (v) to verify your identity; (vi) to investigate and prevent 
                         fraud or other illegal activities; 
                        and (vii) for any other purpose disclosed to you in connection with our Site or Services.
                    </p>

                    <p>
                        Additionally, we use information that we collect about you or that you provide to us, including any personal information:
                        <ul>
                            <li>– To fulfill any other purpose for which you provide it;</li>
                            <li>– To provide you with notices about your account/subscription, including expiration and renewal notices;</li>
                            <li>– To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</li>
                            <li>– To notify you about changes to our Services or any products or services we offer or provide though them;In any other way we may describe when you provide the information;</li>
                            <li>– For any other purpose with your consent.</li>
                        </ul>
                    </p>
                    <h3>4. Information Sharing </h3>

                    <p>

                    </p>
                    <p>
                        Except as described below, personal information you provide to Crewire through our Site or Services will not be shared outside of Crewire and affiliates without your permission.
                    </p>
                    <p>
                        Service Providers. We use a variety of third-party services to help provide our Services, such as hosting our websites, sending out 
                        information, processing transactions, advertising and analyzing our Site and Services. We provide these companies with only those 
                        elements of your personal information they need to deliver those services and these companies and their employees are prohibited 
                        from using that personal information for any other purpose. These third-party service providers may also collect information sent 
                        by your browser as part of a web page request, such as cookies or your IP address. Third-party ad partners may share information with us, 
                        like a browser cookie ID, website URL visited, mobile device ID, or cryptographic hash of a 
                        common account identifier (such as an email address), to help us measure and tailor ads.
                    </p>
                    <p>
                        In connection with transactions. In connection with certain transactions, we may disclose some 
                        or all of your personal information to financial institutions, 
                        government entities and shipping companies or postal services involved in fulfillment. 
                    </p>
                    <p>
                        In connection with acquisitions or divestitures. Circumstances may arise where for strategic or other business reasons 
                        Crewire decides to sell, buy, merge or otherwise reorganize. Such a transaction may involve the disclosure 
                        of personal information to prospective or actual purchasers, or receiving it from sellers. 
                        It is Crewire’s practice to seek appropriate protection for information in these types of transactions.
                    </p>
                    <p>
                        Through the Site or Services. Consistent with the purpose of the Site and Services, we may disclose information you provide 
                        to us to other users of the Site or Services. Accordingly, we may share information about you with people you are connected to 
                        through the Site or Services (e.g. your employees, employers and co-workers) for the purpose of facilitating the Services. We may also disclose personal information 
                        at your direction or to otherwise fulfill the purpose for which you provide it.
                    </p>
                    <p>
                        Other reasons. Notwithstanding anything to the contrary in this Privacy Policy, we may preserve or 
                        disclose your information if we believe it is reasonably necessary to comply with a law, regulation, 
                        or legal request; to protect the safety of any person; to address fraud, security or technical issues; 
                        or to protect Crewire’s rights or property. Nothing in this Privacy Policy is intended to limit any legal defenses 
                        or objections that you may have to a third party’s, including a government’s, request to disclose your information.
                    </p>
                    <h3>5. Security and Storage of Your Information </h3>

                    <p>
                        Crewire is committed to protecting the security of your personal information. We use a variety of commercially reasonable security technologies and procedures to help protect your personal information from unauthorized access, use or disclosure. For example, we store the personal information you provide on encrypted computer servers, requiring two-factor authentication for all system administrators.
                    </p>
                    <p>
                        The safety and security of your information also depends on you. It is your responsibility to ensure the security of your passwords and not to reveal this information to others. We urge you to be careful about giving out information in public areas of the Site or Services. The information you share in public areas may be viewed by any user of the Site or Services.
                    </p>
                    <p>
                        Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to 
                        protect your personal information, we cannot guarantee the security of your personal information transmitted to our 
                        Site or Services. Any transmission of personal information is at your own risk and we are not responsible for 
                        circumvention of any privacy settings or security measures contained on the Site or Services. In the event that 
                        any information under our control is compromised as a result of a breach of security, we will take reasonable 
                        steps to investigate the situation and where appropriate, notify those individuals whose information may 
                        have been compromised and take other steps, in accordance with any applicable laws and regulations.
                    </p>

                    <h3>6. Your Choice Regarding Your Information</h3> 

                   <p>
                    In some cases, you may change your data sharing preferences, as well as review and correct personal information provided through our Site or Services by going to the page on which you provided the information or on your profile page. Where functionality exists on the Site or Services to permit you to amend or update your information directly, you should first attempt to make such desired changes there.
                   </p>
                   <p>
                    If you are unable to amend or update your information directly using the Site or Services, you can make a request to review and correct your personal information collected via our Site or Services or ask Crewire to stop using it by sending an email to info@crewire.io. We may take steps to verify your identity before providing you access to your personal information, and we may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect. You can help us to maintain the accuracy of your information by notifying us of any change to your mailing address, phone number or e-mail address.
                   </p>
                   <p>
                    Data Retention. We will retain your information for as long as your account is active or as needed to provide you services. You may deactivate or delete your account using the Settings page available through the Services. However, please be aware that we will not be able to delete any content you have shared with others on our Site or Services, and copies of some of your data may remain viewable in cached and archived pages. Your employer will have a record of your schedule when you were employed, and will retain the data the employer provided to us about you.
                   </p>
                   <p>
                    Deleting or Anonymizing Your Content. If you are under 18 years of age, you may request to delete or anonymize user content that you posted to the Site or Services such that your personal information will not be identifiable publicly on our Site or Services by contacting us at info@crewire.io. While we will use all commercially reasonable efforts to delete or anonymize your user content upon request, please be aware that due to the nature of our Services, you may not be able to completely remove all of your personally identifiable user content if, for example, that content has been stored, republished or reposted by another user or a third party. We may also maintain your information in identifiable form for our internal use, even if your personal data is no longer visible to the public on our Service.
                   </p>
                    <h3>7. Third Party Sites </h3>

                    <p>
                        Our Site or Services may contain links to and from third party websites of business partners, advertisers, social media sites, professional and government organizations, publications, and our users may post links to third party websites. While we try to link only to sites that share our high standards and respect for privacy, we are not responsible for the content, security or privacy practices employed by other sites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for their policies. Please check the individual policies before you submit any information to those websites. 

                    </p>
                    <h3>8. Children’s Information </h3>

                    <p>
                        Our Site and Services are not intended for anyone under the age of 13. We do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on or through our Services. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at info@crewire.io. 

                    </p>
                    <h3>9. Contact Information </h3>

                    <p>
                        If you have a privacy concern or question regarding Crewire or this Privacy Policy, please contact us using the Contact Us page available through Site and Services. 

                    </p>
                    <h3>10. Changes to Our Privacy Policy </h3>

                    <p>
                        We reserve the right to update this Privacy Policy from time to time. When we do, we will revise the “last updated” date at the top and bottom of this Privacy Policy. The most current version of the Policy will govern our use of your information. Your continued use of the Site and / or Services constitutes your acceptance of the revised Privacy Policy. 

                    </p>                
                </div>
            </div>
        </div> -->
    </footer>

</template>

<script>
import {ref} from 'vue';
    export default {
        name: 'qoo-footer',
        setup(){
            const modalvisible = ref(false)
            const toggleModal = function(){
                modalvisible.value = !modalvisible.value
            } 
            return { modalvisible,toggleModal }
        }
    }
</script>

<style lang='scss'>
    @import 'Footer';
    .Privacy h3 {
        font-size: 16px;
        margin: 10px 0;
        line-height: 1.5;
        font-weight: bolder;
    }
    .Privacy p {
        margin: 10px 0;
    }
</style>