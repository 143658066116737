import { createRouter, createWebHistory } from "vue-router";

function lazyLoad(view) {
      return () => import(`../views/${view}.vue`)
}

const routes = [
      {
            path: "/:catchAll(.*)",
            name: "Error404",
            meta: { layout: "default", title: "Page introuvable" },
            component: lazyLoad('Errors/404'),
      },
      {
            path: '/',
            redirect: '/home',
      },
      {
            path: '/home',
            name: 'homePage',
            component: lazyLoad('Home/Home'),
            meta: { layout: "default", title: "Home" , requiresAuth: true },
            props: true,
      },
      {
            path: '/contact-us',
            name: 'ContactUs',
            component: lazyLoad('ContactUs/ContactUs'),
            meta: { layout: "default", title: "ContactUs" , requiresAuth: true },
            props: true,
      },
      {
            path: '/why-vr',
            name: 'WhyVr',
            component: lazyLoad('WhyVr/WhyVr'),
            meta: { layout: "default", title: "WhyVr" , requiresAuth: true },
            props: true,
      },
      {
            path: '/our-platform',
            name: 'ourPlatform',
            component: lazyLoad('ourPlatform/ourPlatform'),
            meta: { layout: "default", title: "ourPlatform" , requiresAuth: true },
            props: true,
      },
      {
            path: '/solution',
            name: 'solution-page',
            component: lazyLoad('solution/solution-page'),
            meta: { layout: "default", title: "solution-page" , requiresAuth: true },
            props: true,
      },
      {
            path: '/privacy',
            name: 'privacy-page',
            component: lazyLoad('privacy/privacy-page'),
            meta: { layout: "default", title: "privacy-page" , requiresAuth: true },
            props: true,
      },
];

const router = new createRouter({
      history: createWebHistory(),
      routes,
      linkActiveClass: 'active-link',
      linkExactActiveClass: 'exact-active-link',
});

export default router;